<template>
  <v-container fluid>
    <v-card-title class="subheading font-weight-bold justify-center">
      Daftar Deposito
    </v-card-title>
    <div class="tables-basic" v-show="tableShow">
      <v-row>
        <v-col cols=12>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="dataPinjaman"
              :search="search"
            >
              <!-- <template v-slot:item.peminjaman_sisa="{ item }">
                
                {{ Number(item.peminjaman_sisa).toLocaleString() }}
              </template> -->
              <template v-slot:item.saldo="{ item }">
                
                {{ Number(item.saldo).toLocaleString() }}
              </template>
               
               
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
 
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DataKredit',
  data () {
    return {
      tableShow: true,
      tableHistoryShow: false,

      search: '',
      headers: [
          // {
          //   text: 'Aksi',
          //   align: 'center',
          //   filterable: false,
          //   value: 'aksi',
          // },
           
          { text: 'No Deposito', value: 'no_deposito' },
          { text: 'Jenis', value: 'jenis_deposito' },   
          { text: 'Tgl ', value: 'tgl_deposito' },
          { text: 'Nama Nasabah', value: 'nama_nasabah' },
          { text: 'Jatuh Tempo', value: 'jatuhtempo' },
          { text: 'Total Deposito',align: 'right', value: 'saldo' },
           
        ],
      dataPinjaman: [],

      fromPickedDate: new Date().toISOString().substr(0, 10),
      toPickedDate: new Date().toISOString().substr(0, 10),
      txtNasabah: "",
      txtNoNasabah: "",
      txttotalpinjaman:0,
      txtsisapinjaman:0,
      txtjenis:'',
      dataTableSimpanan: [],
      saldoAwal: 0,
      saldoAkhir: 0,
    }
  },
  mounted: function() {
    let formData = {
      'id' : this.user.pk,
    }
     
    if (this.user.roles === "ADMIN"){
      let formData = {
      'id' : ''
      }
      this.axios.post('/getDataDeposito', formData)
      .then((response) => {
        let { data } = response.data
        this.dataPinjaman = data
      })
      .catch((error) => {
        let responses = error.response
        this.setAlert({
          status : true,
          color : 'error',
          text : responses.data.message,
        })
      })
    }

    this.axios.post('/getDataDeposito', formData)
    .then((response) => {
      let { data } = response.data
      this.dataPinjaman = data
    })
    .catch((error) => {
      let responses = error.response
      this.setAlert({
        status : true,
        color : 'error',
        text : responses.data.message,
      })
    })
  },
  computed: {
      ...mapGetters({
          user : 'auth/user',
      }),
  },
  methods: {
    ...mapActions({
      setAlert : 'alert/set',
      setAuth : 'auth/set',
    }),
    detail(item){
      this.tableHistoryShow = true
      this.txtNasabah = item.nasabah_kd+"-"+item.nasabah_nama
      this.txtNoNasabah = item.peminjaman_notrs
      this.txttotalpinjaman=Number(item.peminjaman_total).toLocaleString()
      this.txtsisapinjaman=Number(item.peminjaman_sisa).toLocaleString()
      this.txtjenis=item.jenis
      console.log ('jeis',this.txtjenis)
      this.history()
    },
    filter(){
      this.history()
    },
    history(){
      let formData = new FormData()
      formData.set('nopinjaman', this.txtNoNasabah)
      this.axios.post('/getDataTablePembayaran', formData)
      .then((response) => {
        let { data } = response.data;
        this.dataTableSimpanan = data;

      })
      .catch((error) => {
        let responses = error.response
        this.setAlert({
          status : true,
          color : 'error',
          text : responses.data.message,
        })
      })
    }
  },
}

</script>


<template>
  <v-container fluid>
    <v-row>
        <h1 class="page-title mt-10 mb-6">Daftar Pinjaman</h1>
        <v-col cols=12>
            <v-radio-group
            v-model="row"
            @change="panggilPinjaman"
            row
            >
            <v-radio
                label="Belum Lunas"
                value="2"
            ></v-radio>
            <v-radio
                label="Lunas"
                value="6"
            ></v-radio>
            </v-radio-group>
        </v-col>
    </v-row>
    <div class="tables-basic">
      <v-row>
        <v-col cols=12>
          <v-card class="mb-1">
            <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-center">Aksi</th>
                        <th class="text-center">No</th>                        
                        <th class="text-center">No Pinjaman</th>
                        <th class="text-center">Tgl Pinjam</th>
                        <th class="text-right">Total Pinjam</th>
                        <th class="text-right">Bunga</th>
                        <th class="text-left">Sistem Bunga</th>
                        <th class="text-right">Sisa Pinjaman</th>
                        <th class="text-center">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in dataTable" :key="data.notrs">
                        <td>
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                color="success"
                                class="text-capitalize button-shadow mr-1"
                                @click="detail(data.notrs)"
                                >Detail</v-btn>
                        </td>
                        <td>{{ index+1 }}</td>
                        <td>{{ data.notrs }}</td>
                        <td>{{ data.tgl }}</td>
                        <td  class="text-right">{{ Number(data.totalpeminjaman).toLocaleString() }}</td>
                        <td  class="text-right" v-if="data.periodepinjam ==='Bulanan'">{{ data.bungapinjam }} %</td>
                        <td  class="text-right" v-else>0</td>
                        
                        <td v-if="data.sistempinjam == 4"> Harian </td>
                        <td v-else-if="data.sistempinjam == 0">Tetap</td>
                        <td v-else-if="data.sistempinjam == 1">Menurun</td>
                        <td v-else>Anuitas</td>
                        <!-- <td v-else>{{ (data.sistempinjam=="1" ? "Menurun" : "Anuitas") }}</td> -->
                        <!-- <td v-else>{{ (data.sistempinjam=="0" ? "Tetap" : "Menurun") }}</td> -->
                        <td  class="text-right">{{ Number(data.sisapinjaman).toLocaleString() }}</td>
                        <td>{{ status(data.statusproses) }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <br><br>
    <div class="tables-basic history" v-show="isShowing">
        <center><h4 class="">Histori Pembayaran Pinjaman</h4></center>
        <div v-for="d in dataPinjaman" :key="d.notrs">
            <v-row>
                <v-col cols="col-12" lg="4">
                    <v-text-field label="Tanggal Pinjam"
                                :value="d.tgl"
                                readonly></v-text-field>
                    <v-text-field label="Total Pinjaman"
                                :value="Number(d.totalpeminjaman).toLocaleString()"
                                readonly></v-text-field>
                    <v-text-field  v-if="d.sistempinjam == 4" label="Sistem Bunga" :value='"Harian"'
                                readonly>Harian</v-text-field>
                    <v-text-field v-else label="Sistem Bunga"
                                :value='(d.sistempinjam=="0" ? "Tetap" : "Menurun")'
                                readonly></v-text-field>
                </v-col>
                <v-col cols="col-12" lg="4"></v-col>
                <v-col cols="col-12" lg="4">
                    <v-text-field label="Bunga" v-if="d.sistempinjam != 4"
                                 :value="d.bungapinjam+' %'"
                                readonly></v-text-field>
                     <!-- <v-text-field label="Bunga" v-else 
                                :value="d.bungapinjam+' %'"
                                readonly></v-text-field> -->
                    <v-text-field v-if="d.sistempinjam == 4" label="Jangka Waktu (Hari)"
                                :value="d.lamapinjam"
                                readonly></v-text-field>
                    <v-text-field v-else label="Jangka Waktu (Bln)"
                                :value="d.lamapinjam"
                                readonly></v-text-field>
                    <v-text-field label="Sisa Pinjaman"
                                :value="Number(d.sisapinjaman).toLocaleString()"
                                readonly></v-text-field>
                </v-col>
            </v-row>
        
            <v-card class="mb-1">
            <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-left">No</th>
                        <th class="text-left">Tanggal</th>                        
                        <th v-if="d.sistempinjam != 4" class="text-right">Pokok</th>
                        <th v-if="d.sistempinjam != 4" class="text-right" >Bunga</th>
                        <th  v-if="d.sistempinjam != 4" class="text-right">Denda</th>
                        <th class="text-right">Total Bayar</th>
                        <th class="text-right">Saldo Akhir</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in dataPembayaran" :key="data.pk">
                        <td>{{ index+1 }}</td>
                        <td>{{ data.tgl }}</td>
                        <td v-if="d.sistempinjam != 4" class="text-right">{{ Number(data.pokok).toLocaleString() }}</td>
                        <td v-if="d.sistempinjam != 4"   class="text-right" >{{ Number(data.bunga).toLocaleString() }}</td>
                        <td v-if="d.sistempinjam != 4" class="text-right">{{ Number(data.denda).toLocaleString() }}</td>
                        <td class="text-right">{{ Number(data.totalbayar).toLocaleString() }}</td>
                        <td class="text-right">{{ Number(data.saldoakhir).toLocaleString() }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
            </v-card>
        </div>

    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DaftarPinjaman',
  data () {
    return {
        row: '2',
        isShowing:false,
        dataTable:[],
        dataPinjaman: [],
        dataPembayaran: [],
    }
  },
  computed: {
    ...mapGetters({
          user : 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
        setAlert : 'alert/set',
        setAuth : 'auth/set',
    }),
    status(n){
        if(n == 1) return "Pengajuan"
        if(n == 2) return "Persetujuan"
        if(n == 3) return "Disetujui"
        if(n == 4) return "Pembayaran Kredit"
        if(n == 5) return "Macet"
        if(n == 6) return "Lunas"
    },
    detail(notrs){
        let formData = {
            'notrs' : notrs,
        }

        this.axios.post('/listTPeminjamanByNotrs', formData)
        .then((response) => {
                let { data } = response.data
                this.dataPinjaman = data;
            })
            .catch((error) => {
            let responses = error.response
            this.setAlert({
                status : true,
                color : 'error',
                text : responses.data.message,
            })
        })
        this.axios.post('/listHistoryPembayaran', formData)
        .then((response) => {
                let { data } = response.data
                this.dataPembayaran = data;
                this.isShowing = true
            })
            .catch((error) => {
            let responses = error.response
            this.setAlert({
                status : true,
                color : 'error',
                text : responses.data.message,
            })
        })
    },
    panggilPinjaman(){
      let formData = {
            'id' : this.user.pk,
            'status' :this.row,
        }
      this.axios.post('/listTPeminjaman', formData)
      .then((response) => {
        let { data } = response.data
        this.dataTable = data;
      })
      .catch((error) => {
        let responses = error.response
        this.setAlert({
          status : true,
          color : 'error',
          text : responses.data.message,
        })
      })

    },

  },
  mounted: function() {
    let formData = {
      'id' : this.user.pk,
      'status' :this.row,
    }

    this.axios.post('/listTPeminjaman', formData)
    .then((response) => {
      let { data } = response.data
      this.dataTable = data;
    })
    .catch((error) => {
      let responses = error.response
      this.setAlert({
        status : true,
        color : 'error',
        text : responses.data.message,
      })
    })
  }
}

</script>